import React from "react";
import NdaTemplate from "../data/templates/nda";
import { saveNdaContract } from "../services/firestoreServices";

const NdaContract = ({ formData, setShowContract, onContractSaved }) => {
  const handleContractSubmit = async (formData) => {
    const result = await saveNdaContract(formData);
    if (result.success) {
      onContractSaved(true);
      setShowContract(false);
    } else {
      onContractSaved(false);
    }
  };

  return (
    <div
      id="contractCard"
      className="card mb-4 card-lg card-shadow contract-card"
    >
      <div className="card-header bg-dark">
        <button
          className="float-end btn btn-primary mb-3 btn-sm"
          onClick={() => setShowContract(false)}
        >
          Modify User Information
        </button>
        <h1 className="card-title h2 text-white">Non-Disclosure Agreement</h1>
        <p className="card-text text-white">Last modified: August 15, 2023</p>
      </div>
      <div className="card-body">
        <NdaTemplate formData={formData} onSubmit={handleContractSubmit} />
      </div>
    </div>
  );
};

export default NdaContract;
