import React from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  let navigate = useNavigate();
  const goToHome = () => {
    navigate("/");
  };
  return (
    <main id="content" role="main">
      <div className="container text-center d-flex align-items-center justify-content-center vh-100">
        <div id="userCard" className="card mb-4 card-lg card-shadow user-card">
          <div className="card-body">
            <div className="mb-4">
              <h1 className="display-1 text-primary">404</h1>
              <h3 className="mb-1">Something went wrong</h3>
              <p>The page you are looking for does not exist</p>
              <button
                className="btn btn-outline-primary mt-4"
                onClick={goToHome}
              >
                Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default NotFound;
