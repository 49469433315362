import React from "react";
import "../styles/css/HomePage.css";
import Header from "../components/Header";

const HomePage = () => {
  return (
    <div className="home-page">
      <Header subtitle={"Legal :: Home"} />
      <div id="userCard" className="card mb-4 card-lg card-shadow user-card">
        <div className="card-body">
          <main>
            <section className="intro">
              <h2 className="display-5 text-primary">PadiShip Legal</h2>
              <p>
                At PadiShip, we are committed to providing clear and accessible
                legal information to our users.
              </p>
              <p>
                If you have any questions or need further assistance, please
                don't hesitate to contact our legal team at{" "}
                <a href="mailto:legal@padiship.com">legal@padiship.com</a>.
              </p>
            </section>

            <section className="faq mt-5">
              <h4>Frequently Asked Questions</h4>
              <ul>
                <li>
                  <strong>What is PadiShip?</strong>
                  <p>
                    PadiShip is a revolutionary platform designed to streamline
                    shipping and logistics.
                  </p>
                </li>
                <li>
                  <strong>How can I contact the legal team?</strong>
                  <p>
                    You can reach our legal team by emailing{" "}
                    <a href="mailto:legal@padiship.com">legal@padiship.com</a>.
                  </p>
                </li>
              </ul>
            </section>
          </main>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
