import React, { useState, useEffect, useRef } from "react";
import DOMPurify from "dompurify";
import { checkActiveContract } from "../services/firestoreServices";

function UserForm({ onSubmit }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    title: "",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    stateProv: "",
    postalCode: "",
    country: "",
    effectiveDate: new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }),
  });
  const [errors, setErrors] = useState({});
  const [selectStyle, setSelectStyle] = useState({ color: "#999" });

  useEffect(() => {
    if (formData.country) {
      setSelectStyle({ color: "#000" }); // Change to default color when a country is selected
    } else {
      setSelectStyle({ color: "#999" }); // Placeholder color
    }
  }, [formData.country]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Sanitize the input
    const sanitizedValue = DOMPurify.sanitize(value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: sanitizedValue,
    }));
  };

  const handleTrim = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trim(),
    }));
  };

  async function validateForm(formData, formElements) {
    const errors = {};
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "streetAddress1",
      "city",
      "stateProv",
      "postalCode",
    ]; // Add more required field names here

    // Correctly iterate over formElements
    Array.from(formElements).forEach((element) => {
      const fieldName =
        element.placeholder || element.getAttribute("aria-label");

      if (
        requiredFields.includes(element.name) &&
        !formData[element.name].trim()
      ) {
        errors[element.name] = `${fieldName} is required`;
      }
    });

    // Check if the country dropdown is not selected
    if (!formData.country || formData.country === "Select a country") {
      errors.country = "Country is required";
    }

    // Check if email is formatted correctly
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }

    // Check if title is provided when company is provided
    if (formData.company && !formData.title) {
      errors.title = "Title is required when company is provided";
    }

    // Check if company is provided when title is provided
    if (formData.title && !formData.company) {
      errors.company = "Company is required when title is provided";
    }

    // Check if email already has an active contract
    const activeContract = await checkActiveContract(formData.email);
    if (activeContract) {
      errors.email = "This email already has an active contract";
    }

    return errors;
  }

  const formRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formElements = formRef.current.elements;
    const errors = await validateForm(formData, formElements); // Pass form elements to validateForm
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return; // Stop form submission
    } else {
      setErrors({}); // Clear errors if validation passes
      onSubmit(formData);
    }
  };

  return (
    <div id="userCard" className="card mb-4 card-lg card-shadow user-card">
      <div className="card-body">
        <h3 className="card-title">
          <i className="bi bi-person-lines-fill text-primary me-3" />
          User Information
        </h3>
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="row mt-4">
            <div className="col-sm-6">
              <div className="mb-4">
                <label className="form-label" htmlFor="firstName">
                  Legal first name
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="firstName"
                  id="firstName"
                  placeholder="First name"
                  aria-label="First name"
                  value={formData.firstName}
                  maxLength="50"
                  onChange={handleChange}
                  onBlur={handleTrim}
                />
                {errors.firstName && (
                  <p className="form-error">{errors.firstName}</p>
                )}
              </div>
            </div>

            <div className="col-sm-6">
              <div className="mb-4">
                <label className="form-label" htmlFor="lastName">
                  Legal last name
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="lastName"
                  id="lastName"
                  placeholder="Last name"
                  aria-label="Last name"
                  value={formData.lastName}
                  maxLength="50"
                  onChange={handleChange}
                  onBlur={handleTrim}
                />
                {errors.lastName && (
                  <p className="form-error">{errors.lastName}</p>
                )}
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label className="form-label" htmlFor="email">
              Email address
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              name="email"
              id="email"
              placeholder="Email address"
              aria-label="Email address"
              value={formData.email}
              maxLength="100"
              onChange={handleChange}
              onBlur={handleTrim}
            />
            {errors.email && <p className="form-error">{errors.email}</p>}
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="mb-4">
                <label className="form-label" htmlFor="company">
                  Company
                  <span className="form-label-secondary"> (Optional)</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="company"
                  id="company"
                  placeholder="Company"
                  aria-label="Company"
                  value={formData.company}
                  maxLength="100"
                  onChange={handleChange}
                  onBlur={handleTrim}
                />
                {errors.company && (
                  <p className="form-error">{errors.company}</p>
                )}
              </div>
            </div>

            <div className="col-sm-6">
              <div className="mb-4">
                <label className="form-label" htmlFor="title">
                  Title
                  <span className="form-label-secondary"> (Optional)</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="title"
                  id="title"
                  placeholder="Title"
                  aria-label="Title"
                  value={formData.title}
                  maxLength="100"
                  onChange={handleChange}
                  onBlur={handleTrim}
                />
                {errors.title && <p className="form-error">{errors.title}</p>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="mb-4">
                <label className="form-label" htmlFor="streetAddress1">
                  Street address
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="streetAddress1"
                  id="streetAddress1"
                  placeholder="Street address"
                  aria-label="Street address"
                  value={formData.streetAddress1}
                  maxLength="100"
                  onChange={handleChange}
                  onBlur={handleTrim}
                />
                {errors.streetAddress1 && (
                  <p className="form-error">{errors.streetAddress1}</p>
                )}
              </div>
            </div>

            <div className="col-sm-6">
              <div className="mb-4">
                <label className="form-label" htmlFor="streetAddress2">
                  Street address line 2
                  <span className="form-label-secondary"> (Optional)</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="streetAddress2"
                  id="streetAddress2"
                  placeholder="Street address line 2"
                  aria-label="Street address line 2"
                  value={formData.streetAddress2}
                  maxLength="100"
                  onChange={handleChange}
                  onBlur={handleTrim}
                />
                {errors.streetAddress2 && (
                  <p className="form-error">{errors.streetAddress2}</p>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4 col-md-3">
              <div className="mb-4">
                <label className="form-label" htmlFor="city">
                  City
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="city"
                  id="city"
                  placeholder="City"
                  aria-label="City"
                  value={formData.city}
                  maxLength="50"
                  onChange={handleChange}
                  onBlur={handleTrim}
                />
                {errors.city && <p className="form-error">{errors.city}</p>}
              </div>
            </div>

            <div className="col-sm-4 col-md-3">
              <div className="mb-4">
                <label className="form-label" htmlFor="stateProv">
                  State/Providence
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="stateProv"
                  id="stateProv"
                  placeholder="State/Providence"
                  aria-label="State/Providence"
                  value={formData.stateProv}
                  maxLength="50"
                  onChange={handleChange}
                  onBlur={handleTrim}
                />
                {errors.stateProv && (
                  <p className="form-error">{errors.stateProv}</p>
                )}
              </div>
            </div>

            <div className="col-sm-4 col-md-3">
              <div className="mb-4">
                <label className="form-label" htmlFor="postalCode">
                  Postal code
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="postalCode"
                  id="postalCode"
                  placeholder="Postal code"
                  aria-label="Postal code"
                  value={formData.postalCode}
                  maxLength="20"
                  onChange={handleChange}
                  onBlur={handleTrim}
                />
                {errors.postalCode && (
                  <p className="form-error">{errors.postalCode}</p>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-3">
              <div className="mb-4">
                <label className="form-label" htmlFor="country">
                  Country
                </label>
                <select
                  className="form-select form-control form-control-lg"
                  name="country"
                  id="country"
                  aria-label="Country"
                  value={formData.country}
                  onChange={handleChange}
                  onBlur={handleTrim}
                  style={selectStyle}
                >
                  <option value="" disabled selected>
                    Select a country
                  </option>
                  <option value="AF">Afghanistan</option>
                  <option value="AX">Aland Islands</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AS">American Samoa</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antarctica</option>
                  <option value="AG">Antigua and Barbuda</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaijan</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrain</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BY">Belarus</option>
                  <option value="BE">Belgium</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermuda</option>
                  <option value="BT">Bhutan</option>
                  <option value="BO">Bolivia</option>
                  <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                  <option value="BA">Bosnia and Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BV">Bouvet Island</option>
                  <option value="BR">Brazil</option>
                  <option value="IO">British Indian Ocean Territory</option>
                  <option value="BN">Brunei Darussalam</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambodia</option>
                  <option value="CM">Cameroon</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Cape Verde</option>
                  <option value="KY">Cayman Islands</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CX">Christmas Island</option>
                  <option value="CC">Cocos (Keeling) Islands</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>
                  <option value="CD">
                    Congo, Democratic Republic of the Congo
                  </option>
                  <option value="CK">Cook Islands</option>
                  <option value="CR">Costa Rica</option>
                  <option value="CI">Cote D'Ivoire</option>
                  <option value="HR">Croatia</option>
                  <option value="CU">Cuba</option>
                  <option value="CW">Curacao</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="DK">Denmark</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="DO">Dominican Republic</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egypt</option>
                  <option value="SV">El Salvador</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="ET">Ethiopia</option>
                  <option value="FK">Falkland Islands (Malvinas)</option>
                  <option value="FO">Faroe Islands</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="GF">French Guiana</option>
                  <option value="PF">French Polynesia</option>
                  <option value="TF">French Southern Territories</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germany</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GR">Greece</option>
                  <option value="GL">Greenland</option>
                  <option value="GD">Grenada</option>
                  <option value="GP">Guadeloupe</option>
                  <option value="GU">Guam</option>
                  <option value="GT">Guatemala</option>
                  <option value="GG">Guernsey</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HM">Heard Island and Mcdonald Islands</option>
                  <option value="VA">Holy See (Vatican City State)</option>
                  <option value="HN">Honduras</option>
                  <option value="HK">Hong Kong</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IR">Iran, Islamic Republic of</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Ireland</option>
                  <option value="IM">Isle of Man</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japan</option>
                  <option value="JE">Jersey</option>
                  <option value="JO">Jordan</option>
                  <option value="KZ">Kazakhstan</option>
                  <option value="KE">Kenya</option>
                  <option value="KI">Kiribati</option>
                  <option value="KP">
                    Korea, Democratic People's Republic of
                  </option>
                  <option value="KR">Korea, Republic of</option>
                  <option value="XK">Kosovo</option>
                  <option value="KW">Kuwait</option>
                  <option value="KG">Kyrgyzstan</option>
                  <option value="LA">Lao People's Democratic Republic</option>
                  <option value="LV">Latvia</option>
                  <option value="LB">Lebanon</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libyan Arab Jamahiriya</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MO">Macao</option>
                  <option value="MK">
                    Macedonia, the Former Yugoslav Republic of
                  </option>
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldives</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MH">Marshall Islands</option>
                  <option value="MQ">Martinique</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MX">Mexico</option>
                  <option value="FM">Micronesia, Federated States of</option>
                  <option value="MD">Moldova, Republic of</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="ME">Montenegro</option>
                  <option value="MS">Montserrat</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="MM">Myanmar</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NL">Netherlands</option>
                  <option value="AN">Netherlands Antilles</option>
                  <option value="NC">New Caledonia</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="NF">Norfolk Island</option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="NO">Norway</option>
                  <option value="OM">Oman</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PS">Palestinian Territory, Occupied</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua New Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="PH">Philippines</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="QA">Qatar</option>
                  <option value="RE">Reunion</option>
                  <option value="RO">Romania</option>
                  <option value="RU">Russian Federation</option>
                  <option value="RW">Rwanda</option>
                  <option value="BL">Saint Barthelemy</option>
                  <option value="SH">Saint Helena</option>
                  <option value="KN">Saint Kitts and Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="MF">Saint Martin</option>
                  <option value="PM">Saint Pierre and Miquelon</option>
                  <option value="VC">Saint Vincent and the Grenadines</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">Sao Tome and Principe</option>
                  <option value="SA">Saudi Arabia</option>
                  <option value="SN">Senegal</option>
                  <option value="RS">Serbia</option>
                  <option value="CS">Serbia and Montenegro</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SX">Sint Maarten</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SB">Solomon Islands</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="GS">
                    South Georgia and the South Sandwich Islands
                  </option>
                  <option value="SS">South Sudan</option>
                  <option value="ES">Spain</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="SD">Sudan</option>
                  <option value="SR">Suriname</option>
                  <option value="SJ">Svalbard and Jan Mayen</option>
                  <option value="SZ">Swaziland</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="SY">Syrian Arab Republic</option>
                  <option value="TW">Taiwan, Province of China</option>
                  <option value="TJ">Tajikistan</option>
                  <option value="TZ">Tanzania, United Republic of</option>
                  <option value="TH">Thailand</option>
                  <option value="TL">Timor-Leste</option>
                  <option value="TG">Togo</option>
                  <option value="TK">Tokelau</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad and Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turkey</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TC">Turks and Caicos Islands</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UG">Uganda</option>
                  <option value="UA">Ukraine</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom</option>
                  <option value="US">United States</option>
                  <option value="UM">
                    United States Minor Outlying Islands
                  </option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VE">Venezuela</option>
                  <option value="VN">Viet Nam</option>
                  <option value="VG">Virgin Islands, British</option>
                  <option value="VI">Virgin Islands, U.s.</option>
                  <option value="WF">Wallis and Futuna</option>
                  <option value="EH">Western Sahara</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </select>
                {errors.country && (
                  <p className="form-error">{errors.country}</p>
                )}
              </div>
            </div>
          </div>

          <button id="fillForm" type="submit" className="btn btn-primary me-3">
            Fill form
          </button>
        </form>
      </div>
    </div>
  );
}
export default UserForm;
