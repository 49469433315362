import React, { useState } from "react";

const NdaTemplate = (props) => {
  const formData = props.formData;
  const fullName = `${formData.firstName} ${formData.lastName}`;
  const recipientContact =
    formData.company && fullName
      ? `${fullName} of ${formData.company}`
      : `${fullName}`;
  const fullAddress = `${formData.streetAddress1}, ${
    formData.streetAddress2 ? formData.streetAddress2 + ", " : ""
  }${formData.city}, ${formData.stateProv}, ${formData.postalCode}, ${
    formData.country
  }`;
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // prevent the form from refreshing the page
    if (isChecked) {
      props.onSubmit(formData); // pass the form data back to the parent component
    } else {
      alert("Please agree to the terms and conditions before submitting.");
    }
  };

  return formData ? (
    <div>
      <ol className="multi-level-list">
        <li>
          <span className="section-content">
            <h4 className="special-title">Mutual Non-Disclosure Agreement</h4>
            This Mutual Nondisclosure Agreement (this “<u>Agreement</u>”) is
            entered into by <strong>PadiShip, LLC</strong>, a Georgia limited
            liability corporation located at 1527 Brentwood Drive, Marietta, GA,
            30062, US (“<u>Owner</u>”), and <strong>{recipientContact}</strong>{" "}
            located at {fullAddress} (“<u>Recipient</u>”), effective as of{" "}
            {formData.effectiveDate}.
          </span>
        </li>
        <li>
          <span className="section-content">
            <h4 className="special-title">Background</h4> Owner and Recipient
            (each, a “<u>Party</u>”, and collectively, the “<u>Parties</u>”)
            intend to discuss a possible business arrangement to assist with the
            development of a mobile application (the “Possible Business
            Arrangement”). In connection with those discussions, each Party may
            need to disclose or otherwise make available to the other Party
            certain of its confidential and proprietary information and
            materials. The Parties wish to enter into this Agreement to restrict
            the use and disclosure of that information and materials by the
            receiving Party.
            <br />
            <br />
            In consideration of the Possible Business Arrangement (the validity
            and sufficiency of said consideration both Parties hereby
            acknowledge), the Parties agree as follows:
          </span>
          <ol className="multi-level-list">
            <li>
              <span className="section-content">
                <span className="section-title">
                  Definition of Confidential Information.
                </span>
              </span>
              <ol className="multi-level-list">
                <li>
                  <span className="section-content">
                    “Confidential Information” includes, but is not limited to,
                  </span>
                  <ol className="multi-level-list">
                    <li>
                      <span className="section-content">
                        the terms of this Agreement;
                      </span>
                    </li>
                    <li>
                      <span className="section-content">
                        any other information, communication, or data, in any
                        written form, including, but not limited to, graphic or
                        electronic forms, which the disclosing party identifies
                        in writing as CONFIDENTIAL at the time of disclosure or
                        which is of such a nature that the receiving party
                        should reasonably understand that the disclosing party
                        desires to protect such information against unrestricted
                        disclosure or use, including without limitation,
                        business information, financial data, and marketing
                        data;
                      </span>
                    </li>
                    <li>
                      <span className="section-content">
                        information disclosed orally and subsequently identified
                        in writing as CONFIDENTIAL within thirty (30) days
                        following initial disclosure of such information; and
                      </span>
                    </li>
                    <li>
                      <span className="section-content">
                        the information set forth on <strong>Exhibit A</strong>,
                        attached hereto and made a part hereof. The Party
                        disclosing Confidential Information is referred to in
                        this Agreement as the “Disclosing Party,” and the Party
                        receiving such Confidential Information is referred to
                        as the “Receiving Party.”
                      </span>
                    </li>
                  </ol>
                </li>
                <li>
                  <span className="section-content">
                    Notwithstanding the above, Confidential Information shall
                    not include information which:
                  </span>
                  <ol className="multi-level-list">
                    <li>
                      <span className="section-content">
                        is rightfully in the possession of the Receiving Party
                        prior to disclosure as evidenced by competent written
                        proof;
                      </span>
                    </li>
                    <li>
                      <span className="section-content">
                        is generally known in the public (other than through an
                        unauthorized disclosure);
                      </span>
                    </li>
                    <li>
                      <span className="section-content">
                        is received by the Receiving Party from a third party
                        without a duty of confidentiality, provided that the
                        Receiving Party has no knowledge that such information
                        is subject to a confidentiality obligation; and
                      </span>
                    </li>
                    <li>
                      <span className="section-content">
                        is independently developed by the Receiving Party
                        without reliance on or reference to the Disclosing
                        Party’s Confidential Information.
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">Limitations on Use.</span>
                Confidential Information must be used by the Receiving Party
                only in connection with analysis of, and discussions concerning
                the Possible Business Arrangement. Receiving Party must not use
                Confidential Information at any time, in any fashion, form or
                manner, or for any other purpose.
              </span>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">
                  Limitations on Disclosure.
                </span>
                Receiving Party will use the same measures to protect the
                confidentiality of the Confidential Information that it uses to
                protect the confidentiality of its own proprietary and
                confidential information and materials of like kind, but in no
                event less than a reasonable standard of care. Receiving Party
                will take (and will cause its employees and agents to take)
                commercially reasonable steps required to avoid inadvertent
                disclosure of Confidential Information in Receiving Party’s
                possession.
              </span>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">
                  Access to the Confidential Information.
                </span>
                Access to the Confidential Information must be restricted to
                personnel of Receiving Party engaged in the analysis and
                discussions concerning the Possible Business Arrangement.
                Receiving Party will furnish access to the Confidential
                Information to its employees and third-party contractors
                (collectively, the “Staff”) solely on a need-to-know basis.
                Receiving Party will ensure that its Staff given access to the
                Confidential Information are bound by nondisclosure and limited
                use obligations at least as stringent as those contained in this
                Agreement. Each Party will maintain a list of its employees,
                third-party contractors, and/or agents who have been furnished
                access to the Confidential Information of the other Party. The
                Receiving Party is responsible for any breach of the
                confidentiality provisions of this Agreement by its Staff.
              </span>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">
                  Ownership of Confidential Information; No Licenses.
                </span>
                Confidential Information disclosed by the Disclosing Party to
                the Receiving Party will at all times remain the property of the
                Disclosing Party. No license under any trade secrets,
                copyrights, or other rights is granted under this Agreement or
                by any disclosure of Confidential Information under this
                Agreement.
              </span>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">
                  Copies of Confidential Information.
                </span>
                Confidential Information may not be copied or reproduced by
                Receiving Party without the Disclosing Party’s prior approval.
              </span>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">
                  Return of Confidential Information.
                </span>
                Upon the Disclosing Party’s request or otherwise upon
                termination of discussions regarding the Possible Business
                Arrangement between the Parties, all Confidential Information
                made available under this Agreement, including copies of
                Confidential Information, must be returned to the Disclosing
                Party or destroyed. Notwithstanding the foregoing, the Receiving
                Party shall be entitled to retain one (1) copy of the
                Confidential Information disclosed by the Disclosing Party for
                its records and use in the event of a later dispute between the
                Parties, or a claim against the Disclosing or Receiving Party by
                a third party.
              </span>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">Binding Agreement.</span> This
                Agreement is and will be binding upon the Parties and each of
                their respective parents, affiliates and subsidiaries, and upon
                their respective heirs, successors, representatives and
                permitted assigns.
              </span>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">Governing Law.</span> The
                validity, performance, construction, and effect of this
                Agreement will be governed by the laws of the State of Georgia
                in the United States, without regard to that state’s conflict of
                law provisions.
              </span>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">Equitable Remedies.</span> The
                Parties recognize that serious injury could result to the
                Disclosing Party and its business if the Receiving Party
                breaches its obligations under this Agreement. Therefore,
                Receiving Party agrees that the Disclosing Party will be
                entitled to seek a restraining order, injunction or other
                equitable relief if Receiving Party breaches its obligations
                under this Agreement, in addition to any other remedies and
                damages that would be available at law or equity.
              </span>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">Compelled Disclosures.</span> In
                the event the Receiving Party is required to disclose any
                Confidential Information by order of a court or any government
                agency, by law, regulation, judicial or administrative process,
                the Receiving Party shall:
              </span>
              <ol className="multi-level-list">
                <li>
                  <span className="section-content">
                    give prior written notice of such disclosure to the
                    Disclosing Party (if legally permitted to do so) together
                    with a copy of the material proposed to be disclosed;
                  </span>
                </li>
                <li>
                  <span className="section-content">
                    reasonably cooperate with the Disclosing Party at the
                    Disclosing Party’s request and expense to resist or limit
                    such disclosure or to obtain a protective order; and
                  </span>
                </li>
                <li>
                  <span className="section-content">
                    in the absence of a protective order or other remedy,
                    disclose only that portion of the Confidential Information
                    that is legally required to be disclosed and assure that, if
                    applicable, confidential treatment will be accorded the
                    disclosed information.
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">Publicity.</span> Neither Party
                shall:
              </span>
              <ol className="multi-level-list">
                <li>
                  <span className="section-content">
                    advertise or otherwise make known to others any information
                    regarding this Agreement including, but not limited to, any
                    fee or commission arrangements
                  </span>
                </li>
                <li>
                  <span className="section-content">
                    use any endorsement, quote, or picture implying endorsement
                    of the other Party or its employee(s), in any advertising,
                    sales promotion, press releases, or other public document;
                    or
                  </span>
                </li>
                <li>
                  <span className="section-content">
                    use or display the name or mark(s) of the other Party
                    without the express written consent of the other Party. This
                    Section shall survive the expiration or earlier termination
                    of this Agreement.
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">
                  Non-Solicitation of Employees.
                </span>
                During the pendency of discussions concerning the Possible
                Business Arrangement between the Parties, and for a period of
                six (6) months following termination of such discussions,
                neither Party will solicit the employment of any employee of the
                other Party that has been directly involved in the discussions
                (each, a “Covered Employee”). Notwithstanding the foregoing, the
                limitations contained in this paragraph will not restrict a
                Party from hiring any Covered Employee who
              </span>
              <ol className="multi-level-list">
                <li>
                  <span className="section-content">
                    responds to a general employment solicitation,
                  </span>
                </li>
                <li>
                  <span className="section-content">
                    is terminated by a Party, or
                  </span>
                </li>
                <li>
                  <span className="section-content">
                    is independently identified to the hiring Party by a
                    recruiter or “head hunter” without any identification
                    assistance from the hiring Party.
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">
                  Term; Survival of Secrecy Obligations.
                </span>
                This Agreement will terminate upon the first to occur of:
              </span>
              <ol className="multi-level-list">
                <li>
                  <span className="section-content">
                    termination of discussions between the Parties concerning
                    the Possible Business Arrangement;
                  </span>
                </li>
                <li>
                  <span className="section-content">
                    if a business arrangement is entered into, upon termination
                    of the business arrangement; or
                  </span>
                </li>
                <li>
                  <span className="section-content">
                    delivery of written notice of termination by either Party to
                    the other Party. Following termination, the obligations of
                    Receiving Party under this Agreement with respect to the
                    Confidential Information of Disclosing Party will continue
                    in full force and effect as follows:
                  </span>
                  <ol className="multi-level-list">
                    <li>
                      <span className="section-content">
                        in the case of any information or materials that
                        constitute a trade secret within the meaning of
                        applicable law, for as long as such information and
                        materials remain a trade secret; or
                      </span>
                    </li>
                    <li>
                      <span className="section-content">
                        in the case of any other information or materials, for a
                        term of three (3) years from the date of disclosures.
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">Interpretation.</span> The
                following rules of interpretation must be applied in
                interpreting this Agreement: (a) the headings used in this
                Agreement are for reference and convenience only and will not
                enter into the interpretation of this Agreement; (b) the
                provisions of the Exhibit(s) to this Agreement are incorporated
                into this Agreement; (c) as used in this Agreement, the term
                “including” will always be deemed to mean “including, without
                limitation;” and (d) this Agreement shall not be construed
                against either Party as the drafter of this Agreement.
              </span>
              <ol className="multi-level-list">
                <li>
                  <span className="section-content">
                    the headings used in this Agreement are for reference and
                    convenience only and will not enter into the interpretation
                    of this Agreement;
                  </span>
                </li>
                <li>
                  <span className="section-content">
                    the provisions of the Exhibit(s) to this Agreement are
                    incorporated into this Agreement;
                  </span>
                </li>
                <li>
                  <span className="section-content">
                    as used in this Agreement, the term “including” will always
                    be deemed to mean “including, without limitation;” and
                  </span>
                </li>
                <li>
                  <span className="section-content">
                    this Agreement shall not be construed against either Party
                    as the drafter of this Agreement.
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">No Commitment.</span> Nothing in
                this Agreement will constitute a commitment by either Party to
                develop or disclose any information or materials, including any
                Confidential Information, or to acquire or recommend any
                product, service or asset of the other Party. The provision of
                Confidential Information to Receiving Party as contemplated
                under this Agreement and discussions held in connection with the
                Possible Business Arrangement between the Parties will not
                prevent either Party from pursuing similar discussions with
                third parties or obligate either Party to continue discussions
                with the other Party, nor will either Party otherwise be
                obligated to take, continue or forego any action with respect to
                the Possible Business Arrangement. Disclosing Party makes no
                warranty as to the accuracy or completeness of any information
                or materials provided in connection with this Agreement.
              </span>
            </li>

            <li>
              <span className="section-content">
                <span className="section-title">Non-Circumvention.</span>
                Both Parties agree that, during the term of this Agreement and
                for a period of three (3) years following the termination or
                expiration of this Agreement, neither Party shall, directly or
                indirectly, circumvent, attempt to circumvent, or otherwise
                avoid the relationship between the other Party and any of its
                customers, clients, business partners, suppliers, vendors, or
                other third parties introduced to them through the course of
                this engagement, without the prior written consent of the other
                party. This includes, but is not limited to,
              </span>
              <ol className="multi-level-list">
                <li>
                  <span className="section-content">
                    seeking or entering into any business transactions with such
                    parties on their own behalf,
                  </span>
                </li>
                <li>
                  <span className="section-content">
                    or on behalf of any other person or entity,
                  </span>
                </li>
                <li>
                  <span className="section-content">
                    or offering services that compete with those of the other
                    party to any such parties.
                  </span>
                </li>
              </ol>
            </li>

            <li>
              <span className="section-content">
                <span className="section-title">
                  No Third Party Beneficiaries.
                </span>
                The provisions of this Agreement are for the sole benefit of the
                Parties, and not for the benefit of any other persons or legal
                entities.
              </span>
            </li>
            <li>
              <span className="section-content">
                <span className="section-title">Notices.</span> Any notice or
                report required under this Agreement will be given in writing by
                personal delivery, by certified or registered mail, return
                receipt requested, or by overnight courier, directed to the
                address of the Party given below or to such other address as may
                be substituted by notice to the other Party. All notices will be
                effective upon receipt.
              </span>
            </li>

            <div class="d-flex flex-column ">
              <div class="row justify-content-center mb-3">
                <div class="col-12 col-md-4 offset-md-1 text-center text-md-start">
                  <p class="mb-0 fw-bold">If to Owner:</p>
                  <ul class="list-unstyled mb-3 mb-md-0">
                    <li>PadiShip, LLC.</li>
                    <li>1527 Brentwood Drive</li>
                    <li>Marietta, GA 30062, US</li>
                  </ul>
                </div>
                <div class="col-12 col-md-4 offset-md-1 text-center text-md-start">
                  <p class="mb-0 fw-bold">If to Recipient:</p>
                  <ul class="list-unstyled">
                    {formData.company ? (
                      <>
                        <li>{formData.company}</li>
                        <li>c/o {fullName}</li>
                      </>
                    ) : (
                      <li>{fullName}</li>
                    )}
                    <li>
                      {formData.streetAddress1}
                      {formData.streetAddress2
                        ? ", " + formData.streetAddress2
                        : ""}
                    </li>
                    <li>
                      {formData.city}, {formData.stateProv}{" "}
                      {formData.postalCode}, {formData.country}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <li>
              <span className="section-content">
                <span className="section-title">Partial Invalidity.</span> If
                any part or parts of this Agreement are held to be invalid, the
                remaining parts of the Agreement will continue to be valid and
                enforceable.
              </span>
            </li>

            <li>
              <span className="section-content">
                <span className="section-title">Entire Agreement.</span> This
                Agreement (including Exhibit A) constitutes the entire agreement
                and understanding of the Parties with respect to the subject
                matter of this Agreement, and supersedes all prior discussions
                and agreements, either oral or written, relating to the subject
                matter of this Agreement.
              </span>
            </li>
          </ol>
        </li>
      </ol>
      <span className="divider-center my-5">Exhibits</span>
      <ol className="multi-level-list">
        <li>
          <span className="section-content">
            <h4 className="exhibit-title special-title">Exhibit A</h4>
            <h5 className="exhibit-subtitle">Confidential Information</h5>
            Confidential Information will include the following information and
            materials of the Parties:
          </span>
          <ol className="multi-level-list">
            <li>
              <span className="section-content">
                The business structure of any program, service or product of a
                Party;
              </span>
            </li>

            <li>
              <span className="section-content">
                All application, operating system, database, communications and
                other computer software, whether now or hereafter existing, and
                all modifications, enhancements, and versions thereof and all
                options with respect thereto, and all future products developed
                or derived there from;
              </span>
            </li>

            <li>
              <span className="section-content">
                All source and object codes, flowcharts, algorithms, coding
                sheets, routines, sub-routines, compilers, assemblers, design
                concepts and related documentation and manuals, and
                methodologies used in the design, development and implementation
                of software products;
              </span>
            </li>

            <li>
              <span className="section-content">
                Marketing and product plans, prospect lists, and pricing
                information (other than published price lists), and vendor
                information and provider data;
              </span>
            </li>

            <li>
              <span className="section-content">
                Customer information (including customer names and addresses,
                contact persons, business information, loss experience and
                insurance information);
              </span>
            </li>

            <li>
              <span className="section-content">
                Financial information and reports;
              </span>
            </li>

            <li>
              <span className="section-content">
                Confidential and proprietary information of a third party that
                is in the possession of a Party;
              </span>
            </li>

            <li>
              <span className="section-content">
                Employee and contractor data;
              </span>
            </li>

            <li>
              <span className="section-content">Training materials;</span>
            </li>

            <li>
              <span className="section-content">
                The existence and terms of this Agreement;
              </span>
            </li>

            <li>
              <span className="section-content">
                The existence of discussions between the Parties concerning the
                Possible Business Arrangement and any proposed terms of that
                Possible Business Arrangement;
              </span>
            </li>

            <li>
              <span className="section-content">
                Research and development plans and results; and
              </span>
            </li>

            <li>
              <span className="section-content">
                Merchandising Designs, Products, or Plans.
              </span>
            </li>
          </ol>
        </li>
      </ol>

      <span className="divider-center my-5">Agreement</span>

      <div class="d-flex flex-column ">
        <div class="row justify-content-center mb-3">
          <div class="col-12 col-md-4 offset-md-1 text-center text-md-start">
            <p class="mb-0 fw-bold">Agreed and Accepted:</p>
            <ul class="list-unstyled mb-3 mb-md-0">
              <li>PadiShip, LLC.</li>
              <li>Matthew Keller</li>
              <li>Chief Delivery Officer</li>
              <li>1527 Brentwood Drive</li>
              <li>Marietta, GA 30062, US</li>
            </ul>
          </div>
          <div class="col-12 col-md-4 offset-md-1 text-center text-md-start">
            <p class="mb-0 fw-bold">Agreed and Accepted:</p>
            <ul class="list-unstyled">
              {formData.company ? (
                <>
                  <li>{formData.company}</li>
                  <li>{fullName}</li>
                  <li>{formData.title}</li>
                </>
              ) : (
                <li>{fullName}</li>
              )}
              <li>
                {formData.streetAddress1}
                {formData.streetAddress2 ? ", " + formData.streetAddress2 : ""}
              </li>
              <li>
                {formData.city}, {formData.stateProv} {formData.postalCode},{" "}
                {formData.country}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row mt-4">
          <div className="col-sm-12">
            <div className="ms-md-5">
              {/* <div class="row mt-5"> */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="agreement"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="agreement">
                  I agree to the terms and conditions of this contract in its
                  entirety.
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12">
            <div className="mb-4">
              <button
                className="float-end btn btn-primary mb-3 btn-md d-block"
                type="submit"
                disabled={!isChecked}
              >
                Sign Contract
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="row mt-4">
        <p className="footnote">
          Under the The Electronic Signatures in Global and National Commerce
          Act, you have the right to request a paper copy of the contract to be
          signed.
          <br />
          Please contact legal@padiship.com if you wish to request a paper copy
          instead.
        </p>
      </div>
    </div>
  ) : null;
};

export default NdaTemplate;
