import React from "react";
import logo from "../assets/images/logos/logo.png";
import logoChip from "../assets/images/logos/legal_hub_chip_trans_bg_sm.png";
import { Link } from "react-router-dom";

const Header = ({ subtitle }) => {
  return (
    <header
      id="header"
      className="navbar navbar-end navbar-light navbar-absolute-top navbar-scrolled"
    >
      <div className="container">
        <nav className="js-mega-menu navbar-nav-wrap">
          <Link className="navbar-brand" href="/" aria-label="PadiShip">
            <img className="navbar-brand-logo" src={logo} alt="PadiShip Logo" />
            <div class="chip">{subtitle}</div>
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
