import React from "react";
import { useNavigate } from "react-router-dom";

const SuccessComponent = () => {
  let navigate = useNavigate();
  const goToHome = () => {
    navigate("/");
  };
  return (
    <div>
      <div className="container text-center d-flex align-items-center justify-content-center vh-100">
        <div id="userCard" className="card mb-4 card-lg card-shadow user-card">
          <div className="card-body">
            <div className="mb-4">
              <h1 className="display-3 text-success">Success</h1>
              <p>Your NDA contract has been signed successfully.</p>
              <button
                className="btn btn-outline-primary mt-4"
                onClick={goToHome}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessComponent;
