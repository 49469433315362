import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDbLPt47XqU8LzsnCYZI6ZJWoBbD_qD_6I",
  authDomain: "padiship---production.firebaseapp.com",
  projectId: "padiship---production",
  storageBucket: "padiship---production.appspot.com",
  messagingSenderId: "689444198033",
  appId: "1:689444198033:web:ae6dfb003c51358a08d130",
  measurementId: "G-EMB0H0CQVD",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app, "legal");
const analytics = getAnalytics(app);

export { db };
