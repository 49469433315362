import React, { useState, useEffect } from "react";
import UserForm from "../components/UserForm";
import NdaContract from "../components/NdaContract";
import Header from "../components/Header";
import ModalComponent from "../components/Modal";
import { validate as uuidValidate } from "uuid";
import { useNavigate } from "react-router-dom";
import SuccessComponent from "../components/Success";

const NdaPage = () => {
  const [showContract, setShowContract] = useState(false);
  const [formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSavedSuccessfully, setIsSavedSuccessfully] = useState(false);

  let navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlContractCode = urlParams.get("c");

  const addDashesToUuid = (uuid) => {
    return (
      uuid.slice(0, 8) +
      "-" +
      uuid.slice(8, 12) +
      "-" +
      uuid.slice(12, 16) +
      "-" +
      uuid.slice(16, 20) +
      "-" +
      uuid.slice(20)
    );
  };

  let urlContractCodeWithDashes = urlContractCode;
  if (urlContractCode !== null) {
    urlContractCodeWithDashes = addDashesToUuid(urlContractCode);
  }

  useEffect(() => {
    if (!uuidValidate(urlContractCodeWithDashes)) {
      setModalTitle("Invalid Link");
      setModalContent("The unique link you are trying to access is invalid.");
      setShowModal(true);
      setIsError(true);
    }
  }, []);

  const handleModalClose = () => {
    setShowModal(false);
    if (isError) {
      navigate("/NotFound");
    }
  };

  const handleUserFormSubmit = (formData) => {
    setFormData(formData);
    setShowContract(true);
  };

  const handleContractSaved = (success) => {
    if (success) {
      setIsSavedSuccessfully(true);
      setModalTitle("Success");
      setModalContent("Your NDA contract has been saved successfully.");
      setShowModal(true);
      setIsError(false);

      navigate("/success", { replace: true });
    } else {
      setModalTitle("Oh snap");
      setModalContent(
        "There was an error saving your NDA contract. Please try again."
      );
      setShowModal(true);
      setIsError(true);
    }
  };

  return (
    <div>
      <Header subtitle={"Legal :: Contracts"} />
      <ModalComponent
        showModal={showModal}
        handleClose={handleModalClose}
        title={modalTitle}
        children={<p>{modalContent}</p>}
      />
      <div className="container content-space-2">
        <div className="w-lg-100 mx-lg-auto">
          {!isSavedSuccessfully && !showContract && (
            <UserForm onSubmit={handleUserFormSubmit} />
          )}
          {!isSavedSuccessfully && showContract && (
            <NdaContract
              formData={formData}
              setShowContract={setShowContract}
              onContractSaved={handleContractSaved}
            />
          )}
          {isSavedSuccessfully && <SuccessComponent />}
        </div>
      </div>
    </div>
  );
};

export default NdaPage;
