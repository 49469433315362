import { db } from "../firebaseConfig";
import { v4 as uuidv4, validate as uuidValidate } from "uuid";
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  limit,
  Timestamp,
} from "firebase/firestore";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const urlContractCode = urlParams.get("c");

export const fetchActiveContractTemplate = async () => {
  const q = query(
    collection(db, "Contracts/nonDisclosureAgreement/Templates/"),
    where("isActive", "==", true),
    where("effectiveDttm", "<=", new Date().toISOString()),
    limit(1)
  );

  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const allData = querySnapshot.docs.map((doc) => doc.data());
      return querySnapshot.docs[0].data(); // Return the contract data
    } else {
      console.log("No active document found.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching contract:", error);
    throw error; // Rethrow to handle it in the component
  }
};

export const checkActiveContract = async (email) => {
  const q = query(
    collection(db, "Contracts/nonDisclosureAgreement/Signed/"),
    where("isSigned", "==", true),
    where("effectiveDttm", "<=", new Date()),
    where("expirationDttm", ">", new Date()),
    where("email", "==", email),
    limit(1)
  );

  try {
    console.log(q);
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot);
    if (!querySnapshot.empty) {
      console.log("Active contract found.");
      console.log(querySnapshot.docs[0].data());
      return !querySnapshot.empty;
    }
  } catch (error) {
    console.error("Error fetching contract:", error);
    throw error; // Rethrow to handle it in the component
  }
};

export const saveNdaContract = async (formData) => {
  const {
    firstName,
    lastName,
    email,
    company,
    title,
    streetAddress1,
    streetAddress2,
    city,
    stateProv,
    postalCode,
    country,
    createdDttm = Timestamp.fromDate(new Date()),
    signedDttm = Timestamp.fromDate(new Date()),
    isSigned = true,
    effectiveDttm = Timestamp.fromDate(new Date()),
    expirationDttm = Timestamp.fromDate(new Date("9999-12-31T23:59:59.999Z")),
    contractVersion = "1.0.0",
    // verificationCode = uuidv4().replace(/-/g, ""),
    verificationCode = urlContractCode,
  } = formData;

  const populatedTemplate = `
    NON-DISCLOSURE AGREEMENT
    This agreement is made between ${firstName} ${lastName}, residing at ${streetAddress1}, hereinafter referred to as the "Recipient", and the other party...
    [Include the rest of your NDA template here]
  `;

  try {
    const ndaCollection = collection(
      db,
      "Contracts/nonDisclosureAgreement/Signed/"
    );
    await addDoc(ndaCollection, {
      firstName,
      lastName,
      email,
      company,
      title,
      streetAddress1,
      streetAddress2,
      city,
      stateProv,
      postalCode,
      country,
      createdDttm,
      signedDttm,
      isSigned,
      effectiveDttm,
      expirationDttm,
      contractVersion,
      verificationCode,
    });

    return { success: true };
  } catch (error) {
    console.error("Error saving NDA contract:", error);
    return { success: false, error };
  }
};
